<template>
  <div>
    <!-------------------------------------- Header -------------------------------->
    <div
      class="
        flex
        justify-between
        items-center
        border-0 border-b border-solid border-gray-400
        py-3
        px-2
      "
    >
      <h1 class="border-0 font-semibold text-blackdok text-xl">
        Historique des téléconsultations
      </h1>
      <div class="flex flex-row items-center gap-x-2">
        <label class="font-EffraR">Filter : </label>
        <select
          class="
            bg-transparent
            shadow
            border border-solid border-gray-400
            px-1
            rounded-cu
          "
          v-model="Consultationsfilter"
          @click="filter()"
        >
          <option value="1">Date</option>
          <option value="2">Prix</option>
          <option value="3">Nom A-z</option>
        </select>
      </div>
    </div>

    <!-------------------------------------- Table -------------------------------->
    <div class="px-4 mt-8 pb-10">
      <table
        class="w-full"
        style="border-spacing: 0; border-collapse: collapse"
      >
        <thead>
          <tr>
            <th
              class="
                hidden
                xl:table-cell
                border border-t-0 border-solid border-gray-300
                bg-dokBlue-ultra
                text-white text-lg
                font-EffraR font-normal
                px-2
                py-3
                rounded-tl-lg
              "
            >
              Date et heure
            </th>
            <th
              class="
                hidden
                xl:table-cell
                border border-solid border-t-0 border-gray-300
                bg-dokBlue-ultra
                text-white text-lg
                font-EffraR font-normal
                px-2
                py-3
              "
            >
              Prix
            </th>
            <th
              class="
                hidden
                xl:table-cell
                border border-t-0 border-solid border-gray-300
                bg-dokBlue-ultra
                text-white text-lg
                font-EffraR font-normal
                px-2
                py-3
                rounded-tr-lg
              "
            >
              Patient
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in consultations.slice(
              minPage,
              minPage + limitPage
            )"
            :key="index"
            class="
              flex
              xl:table-row
              flex-row
              xl:flex-row
              flex-wrap
              xl:flex-no-wrap
            "
          >
            <td
              class="
                w-full
                xl:w-auto
                border border-solid border-gray-300
                text-lg
                font-EffraR font-normal
                py-3
                px-2
              "
            >
              <span class="text-gray-600 block xl:hidden rounded-cu mb-1"
                >Date:</span
              >{{ moment()(row.created_at).format("DD . MMMM . YYYY - HH:MM") }}
            </td>
            <td
              class="
                w-full
                xl:w-auto
                border border-solid border-gray-300
                text-lg
                font-EffraR font-normal
                py-3
                px-2
              "
            >
              <span class="text-gray-600 block xl:hidden rounded-cu mb-1"
                >Prix:</span
              >
              {{ row.initPrice || "-" }}
            </td>
            <td
              class="
                w-full
                xl:w-auto
                border border-solid border-gray-300
                text-lg
                font-EffraR font-normal
                py-3
                px-2
              "
            >
              <span class="text-gray-600 block xl:hidden rounded-cu mb-1"
                >Patient:</span
              >
              {{ row.patientId.lastName + " " + row.patientId.lastName || "-" }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="mt-4 mb-4 py-2 flex justify-center items-center"
        v-if="nbPages > 1"
      >
        <div class="flex flex-row">
          <div>
            <a
              href="javascript:void(0)"
              @click="pagination('moin', -1)"
              :class="currentPage == 1 ? 'disable ' : ''"
              class="
                px-4
                py-4
                mx-5
                border-0
                shadow-sm
                text-nblue
                rounded-full
                cursor-pointer
                hover:shadow-lg hover:text-gray-100 hover:bg-nblue
              "
              ><i class="fa fa-angle-left" aria-hidden="true"></i
            ></a>
          </div>
          <div v-for="item in nbPages" :key="item">
            <a
              href="javascript:void(0)"
              @click="pagination('none', item)"
              :class="
                currentPage == item
                  ? 'shadow-lg text-gray-100 bg-nblue disable'
                  : 'text-nblue'
              "
              class="
                px-4
                py-4
                mx-1
                border-0
                shadow-sm
                rounded-full
                cursor-pointer
                hover:shadow-lg hover:text-gray-100 hover:bg-nblue
              "
              >{{ item }}</a
            >
          </div>
          <div>
            <a
              href="javascript:void(0)"
              @click="pagination('plus', 1)"
              :class="currentPage == nbPages || nbPages == 0 ? 'disable ' : ''"
              class="
                px-4
                py-4
                mx-5
                border-0
                shadow-sm
                text-nblue
                rounded-full
                cursor-pointer
                hover:shadow-lg hover:text-gray-100 hover:bg-nblue
              "
              ><i class="fa fa-angle-right" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  export default {
    data() {
      return {
        consultations: [],
        Consultationsfilter: 1,
        currentPage: 1,
        nbPages: 0,
        limitPage: 10,
        minPage: 0,
      };
    },
    async mounted() {
      let doctor = await this.$auth.getUserInfo();
      const { data, ok } = await this.$store.dispatch(
        "doctor/GET_CONSULTIONS_OF_MONTH",
        { id: doctor._id }
      );
      if (ok) {
        this.consultations = data;
        this.nbPages = this.consultations.length / this.limitPage;
      }
    },
    methods: {
      moment() {
        moment.locale("fr");
        return moment;
      },
      filter() {
        this.consultations = this.consultations.sort(function (a, b) {
          if (this.Consultationsfilter == 2) return a.initPrice - b.initPrice;
          else if (this.Consultationsfilter == 3)
            return a.patientId.lastName - b.patientId.lastName;
          else return a.created_at - b.created_at;
        });
      },
      pagination(action, nb) {
        if (String(action) == "plus") {
          if (this.currentPage < this.nbPages) {
            this.currentPage++;
            this.minPage += this.limitPage;
          }
        } else if (String(action) == "moin") {
          if (this.currentPage > 1) {
            this.currentPage--;
            this.minPage -= this.limitPage;
          }
        } else if (String(action) == "none") {
          this.currentPage = nb;
          this.minPage = this.limitPage * nb - this.limitPage;
        }
      },
    },
  };
</script>
